import React from "react";
import { useDispatch } from "react-redux";
import { Typography, TextField, Grid, Checkbox } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { createPaymentTypeAction, modifyPaymentTypeAction } from "../../actions";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";

const gridStyle = { height: 75, padding: "0px 10px" };

const Form = (props) => {

  const { data, handleClose } = props;
  const dispatch = useDispatch();

  const create = (dataToSend) => {
    dispatch(createPaymentTypeAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const modify = (dataToSend) => {
    dispatch(modifyPaymentTypeAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const openValidationCodeError = () => {
    dispatch(openSnackbarAction({ msg: 'El código T está reservado para uso interno, no es posible definirlo', severity: "error", duration: 10000 }));
  }

  const {
    isCreate,
    formData,
    setFormValue,
    submit
  } = useHandleForm(data, create, modify, openValidationError, openValidationCodeError);

  return (
    <Grid container item xs={12} direction="row" style={{ paddingTop: 10 }}>
      <Grid container item xs={12}>
        <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* codigo */}
          <TextField
            disabled={!Boolean(isCreate)}
            required
            fullWidth
            margin={"none"}
            label={'Código'}
            onChange={(e) => setFormValue(e.target.value, "code")}
            value={formData.code}
            inputProps={{ maxLength: 8 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* nombre */}
          <TextField
            required
            fullWidth
            margin={"none"}
            label={'Nombre'}
            value={formData.name}
            onChange={(e) => setFormValue(e.target.value, "name")}
            inputProps={{ maxLength: 60 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} style={gridStyle}>
          <div style={{ display: "flex", alignItems: "center", cursor: "pointer", paddingRight: 20 }} onClick={() => setFormValue(!Boolean(formData.requiresBank), "requiresBank")}>
            <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
              {'Requiere banco'}
            </Typography>
            <Checkbox checked={formData.requiresBank} />
          </div>
          <div style={{ display: "flex", alignItems: "center", cursor: "pointer", paddingRight: 20 }} onClick={() => setFormValue(!Boolean(formData.requiresBankAccount), "requiresBankAccount")}>
            <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
              {'Requiere cuenta bancaria'}
            </Typography>
            <Checkbox checked={formData.requiresBankAccount} />
          </div>
        </Grid>
      </Grid>
      <Grid container item xs={12} justify={"flex-end"} alignItems="center" style={{ paddingBottom: 20 }}>
        <ButtonDialogAction isAccept onClick={submit} text={getLocalizedString("save")} />
      </Grid>
    </Grid>
  );
}

export default Form;
